import { store } from "@/store/store";
import { diagnosticos_y_vacuna } from "@/shared/dtos/Historial_clinico/diagnosticos_y_vacuna";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "diagnosticos_y_vacunaModule",
  store,
  dynamic: true,
})
class diagnosticos_y_vacunaModule extends VuexModule {
  public vacunas: diagnosticos_y_vacuna[] = [];
  public diagnosticos: diagnosticos_y_vacuna[] = [];

  @Action({ commit: "onGetVacunas" })
  public async getVacunas_like(nombre: string) {
    return await ssmHttpService.get(
      API.diagnosticos_y_vacuna + "/vacunas_like?like_nombre=" + nombre
    );
  }

  @Action({ commit: "onGetVacunas" })
  public async getVacunas() {
    return await ssmHttpService.get(
      API.diagnosticos_y_vacuna + "/vacunas",
      null,
      false
    );
  }

  @Action({ commit: "onGetDiagnosticos" })
  public async getDiagnosticos() {
    return await ssmHttpService.get(
      API.diagnosticos_y_vacuna + "/diagnostico",
      null,
      false
    );
  }

  @Action({ commit: "onGetDiagnosticos" })
  public async getDiagnosticos_like(nombre: string) {
    return await ssmHttpService.get(
      API.diagnosticos_y_vacuna + "/diagnostico_like?like_nombre=" + nombre
    );
  }

  @Action({ commit: "onGetDiagnosticos" })
  public async gethist_clinicos_diagnosticos(id_paciente: number) {
    return await ssmHttpService.get(
      API.hist_clinico + "/diagnosticos_acivas/" + id_paciente
    );
  }

  @Action({ commit: "onGetVacunas" })
  public async gethist_clinicos_vacunas(id_paciente: number) {
    return await ssmHttpService.get(
      API.hist_clinico + "/vacunas_acivas/" + id_paciente
    );
  }

  @Action
  public async guardardiagnosticos_y_vacuna(
    diagnosticos_y_vacuna: diagnosticos_y_vacuna
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.diagnosticos_y_vacuna,
      diagnosticos_y_vacuna.toJson()
    );
  }

  @Action
  public async modificardiagnosticos_y_vacuna(
    diagnosticos_y_vacuna: diagnosticos_y_vacuna
  ) {
    await ssmHttpService.put(API.diagnosticos_y_vacuna, diagnosticos_y_vacuna);
  }

  @Action
  public async eliminardiagnosticos_y_vacuna(
    diagnosticos_y_vacuna: diagnosticos_y_vacuna
  ) {
    await ssmHttpService.delete(
      API.diagnosticos_y_vacuna + "/" + diagnosticos_y_vacuna.id,
      null,
      false
    );
  }

  @Mutation
  public onGetDiagnosticos(res: diagnosticos_y_vacuna[]) {
    this.diagnosticos = res ? res.map((x) => new diagnosticos_y_vacuna(x)) : [];
  }
  @Mutation
  public onGetVacunas(res: diagnosticos_y_vacuna[]) {
    this.vacunas = res ? res.map((x) => new diagnosticos_y_vacuna(x)) : [];
  }
}
export default getModule(diagnosticos_y_vacunaModule);
